import { JapaneseLastNameEntry } from "./types";
import { hatsuon, PitchHeight } from "hatsuon";

import styled, { css } from "styled-components";

const CirclesMap = [
  "⓪",
  "①",
  "②",
  "③",
  "④",
  "⑤",
  "⑥",
  "⑦",
  "⑧",
  "⑨",
  "⑩",
  "⑪",
  "⑫",
  "⑬",
  "⑭",
  "⑮",
  "⑯",
  "⑰",
  "⑱",
  "⑲",
  "⑳",
  "㉑",
  "㉒",
  "㉓",
  "㉔",
  "㉕",
  "㉖",
  "㉗",
  "㉘",
  "㉙",
  "㉚",
  "㉛",
  "㉜",
  "㉝",
  "㉞",
  "㉟",
  "㊱",
  "㊲",
  "㊳",
  "㊴",
  "㊵",
  "㊶",
  "㊷",
  "㊸",
  "㊹",
  "㊺",
  "㊻",
  "㊼",
  "㊽",
  "㊾",
  "㊿",
];
const StyledMora = styled.span<{ pitchHeight: PitchHeight }>`
  ${(props) =>
    props.pitchHeight === 1 &&
    css`
      border-top: black 2px solid;
    `}
`;

type MoraInformationType = {
  moraCharacters: string;
  pitchHeight: PitchHeight;
};

const Mora = ({
  moraInformation,
}: {
  moraInformation: MoraInformationType;
}) => {
  return (
    <StyledMora pitchHeight={moraInformation.pitchHeight}>
      {moraInformation.moraCharacters}
    </StyledMora>
  );
};

const AccentCircle = styled.span`
  font-size: 30px;
`;

const LastNameHiraganaContainer = styled.div`
  font-size: 20px;
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const MoraContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

const getFirstValidAccent = (entry: JapaneseLastNameEntry): number | null => {
  const accentList = [
    entry.accent_A,
    entry.accent_B,
    entry.accent_C,
    entry.accent_D,
  ];
  const parsedList = accentList.map((item) => parseInt(item!));
  const filteredList = parsedList.filter(Number.isInteger);
  if (filteredList.length >= 1) {
    return filteredList[0];
  }
  return null;
};

export const LastNameHiragana = ({
  japaneseLastNameEntry,
}: {
  japaneseLastNameEntry: JapaneseLastNameEntry;
}) => {
  const accent = getFirstValidAccent(japaneseLastNameEntry);
  const pitchAccentInfo = hatsuon({
    reading: japaneseLastNameEntry.hiragana,
    pitchNum: accent,
  });
  const moraInformation: MoraInformationType[] = pitchAccentInfo.morae.map(
    (mora: string, index: number) => {
      return {
        moraCharacters: mora,
        pitchHeight: pitchAccentInfo.pattern[index],
      };
    }
  );
  return (
    <LastNameHiraganaContainer>
      <AccentCircle>{accent !== null && CirclesMap[accent]}</AccentCircle>
      <MoraContainer>
        {moraInformation.map((moraInfo, index) => (
          <Mora key={index} moraInformation={moraInfo} />
        ))}
      </MoraContainer>
    </LastNameHiraganaContainer>
  );
};
