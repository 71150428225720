import React, { useEffect } from "react";
import "./App.css";
import axios from "axios";
import { useState } from "react";
import LoginButton from "./LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";
import { LastNameHiragana } from "./LastNameHiragana";
import { JapaneseLastNameEntry } from "./types";
import { apiPrefix } from "./consts";
import { AutocompleteSearch } from "./AutocompleteSearch";
import styled from "styled-components";

export const SearchContainer = styled.div`
  width: 400px;
`;

const ResultContainer = styled.div`
  margin-top: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 80px;
`;

const KanjiContainer = styled.div`
  margin-top: 25px;
  font-size: 14px;
`;

const AppStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function App() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const getUserMetadata = async () => {
      try {
        setAccessToken(
          await getAccessTokenSilently({
            audience: `last name api`,
            scope: "read:last-names",
          })
        );
      } catch (e: any) {
        console.log(e.message);
      }
    };
    getUserMetadata();
  }, [getAccessTokenSilently, user?.sub]);

  const [result, setResult] = useState<JapaneseLastNameEntry>();
  const handleSubmit = async (nameToSearch: string) => {
    console.log(`I am looking for ${nameToSearch}`);
    const result = await axios.post(
      `${apiPrefix}/search/`,
      {
        hiragana: nameToSearch,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    setResult(result.data);
  };

  return (
    <AppStyled className="App">
      {isAuthenticated ? <LogoutButton /> : <LoginButton />}
      <h1>名字のアクセント</h1>
      {isAuthenticated && (
        <>
          <SearchContainer>
            <AutocompleteSearch
              onSelectOption={handleSubmit}
              accessToken={accessToken || ""}
            />
          </SearchContainer>
          {result && (
            <ResultContainer>
              <LastNameHiragana japaneseLastNameEntry={result} />
              <KanjiContainer>{result.kanji.join(", ")}</KanjiContainer>
            </ResultContainer>
          )}
        </>
      )}
    </AppStyled>
  );
}
