import AsyncSelect from "react-select/async";

import axios from "axios";
import { apiPrefix } from "./consts";
import { JapaneseLastNameEntry } from "./types";
import { OnChangeValue } from "react-select/dist/declarations/src/types";
import { useState } from "react";

type AutocompleteResult = Omit<
  JapaneseLastNameEntry,
  "accent_A" | "accent_B" | "accent_C" | "accent_D"
>;

export const AutocompleteSearch = ({
  onSelectOption,
  accessToken,
}: {
  onSelectOption: (hiragana: string) => void;
  accessToken: string;
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const onSearch = (newValue: OnChangeValue<JapaneseLastNameEntry, false>) => {
    onSelectOption(newValue?.hiragana || "");
  };

  const loadOptions = (
    inputValue: string,
    callback: (options: AutocompleteResult[]) => void
  ) => {
    const fetchData = async () => {
      const result = await axios.post(
        `${apiPrefix}/autocomplete/`,
        {
          hiragana: inputValue,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log(result);
      callback(result.data);
    };
    fetchData();
  };

  const noOptionsMessage =
    inputValue === "" ? `Type to search` : `No matches found`;
  return (
    <AsyncSelect
      value={value}
      inputValue={inputValue}
      placeholder={"（例：さいとう）"}
      cacheOptions
      noOptionsMessage={() => <>{noOptionsMessage}</>}
      formatOptionLabel={(data: AutocompleteResult) => {
        return (
          <span>
            {data.hiragana}　({data.kanji.join("/")})
          </span>
        );
      }}
      getOptionValue={(option: AutocompleteResult) => {
        return option.hiragana;
      }}
      loadOptions={loadOptions}
      onChange={onSearch}
      onInputChange={setInputValue}
    />
  );
};
